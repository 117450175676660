import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ModalContainerT, MusicData, MusicOptions } from "../../types";
import { useUploadMutation } from "../../hooks";
import { ModalContainer } from "../ModalContainer";
import { LargeSpinner } from "..";

export const AddMusicModel = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  const [musicData, setMusicData] = useState<MusicData>({
    track_title: "",
    track_details: "",
    artist_name: "",
    other_type: "",
    track_type: "",
    images: null,
    audios: null,
  });

  const mutation = useUploadMutation<MusicData>({
    cacheId: "music",
    endpoint: "music",
  });

  const handleUploadMusic = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate({
      track_title: musicData.track_title,
      track_details: musicData.track_details,
      artist_name: musicData.artist_name,
      track_type: musicData.track_type,
      other_type: musicData.other_type,
      images:musicData.images,
      audios:musicData.audios,
    });
  };

  const musicDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setMusicData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      
      
      props.closeModal();
      // location.reload();
    }
  }, [mutation, props]);
 console.log(mutation);
  return (
    <ModalContainer
      modalTitle="Add Music"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>Music uploade </h3>
      ) : null}

      <form
        style={{ width: "400px" }}
        className="py-3 px-5"
        onSubmit={handleUploadMusic}
      >
        <div className="mb-1 w-full">
          <label
            htmlFor="title"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Title
          </label>

          <input
            type="text"
            id="track_title"
            name="track_title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="Title"
            value={musicData.track_title}
            onChange={musicDataChange}
          />
        </div>

        <div>
          <div className="mb-1 w-full ">
            <label
              htmlFor="artist_name"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Artist Name
            </label>

            <input
              type="text"
              id="artist_name"
              name="artist_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Artist Name"
              value={musicData.artist_name}
              onChange={musicDataChange}
            />
          </div>
          <div className="flex">
            <div className="mb-1 w-full">
              <label
                htmlFor="track_type"
                className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
              >
                Track Type
              </label>

              <select
                id="track_type"
                name="track_type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
                onChange={musicDataChange}
                value={musicData.track_type || "default"}
              >
                <option value={"default"} disabled>
                  __select__
                </option>

                {MusicOptions.music.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-1 w-full">
              <label
                htmlFor="other_type"
                className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
              >
                Other Type
              </label>

              <select
                id="other_type"
                name="other_type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
                onChange={musicDataChange}
                value={musicData.other_type || "default"}
              >
                <option value={"default"} disabled>
                  __select__
                </option>
                <option value="music">music</option>
                <option value="instruments">Instruments</option>
              </select>
            </div>
          </div>

          <div className="mb-1 w-full ">
            <label
              htmlFor="price"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Track Details
            </label>

            <input
              type="text"
              id="track_details"
              name="track_details"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="track_details"
              value={musicData.track_details}
              onChange={musicDataChange}
            />
          </div>
        </div>

        <div className="mb-1 w-full">
          <label
            htmlFor="images"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Images
          </label>

          <input
            type="file"
            id="images"
            className="bg-gray-50 text-sm rounded block w-full"
            multiple
            accept="image/*"
            onChange={(event) =>
              setMusicData((prevData) => ({
                ...prevData,
                images: event.target.files,
              }))
            }
          />
        </div>
        <div className="mb-1 w-full">
          <label
            htmlFor="audios"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Audios
          </label>

          <input
            type="file"
            id="audios"
            maxLength={3}
            className="bg-gray-50 text-sm rounded block w-full"
            multiple
            accept="audio/*"
            onChange={(event) =>
              setMusicData((prevData) => ({
                ...prevData,
                audios: event.target.files,
              }))
            }
          />
        </div>
        <div>
          <button className="w-full bg-primary py-2 rounded text-white tracking-wider">
            Upload Music
          </button>
        </div>
      </form>
    </ModalContainer>
  );
}
