import { MdDownload, MdRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom";
import { Increment } from "../../hooks/Increment";
import { useState } from "react";

export const MusicCard = ({
  title,
  image,
  details_link,
  id,
  downloads,
  artist_name,
  views,
  audios,
}) => {
  const words = title.split(" ");
  const newTitle = words.slice(0, 10).join(" ");
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const handleDownload = (event, musicId: number) => {
    event.preventDefault();
    const url = "https://api.mccullaghmusic.com/";
    Increment({
      link: `${url}api/v1/increment/${musicId}/downloads`,
    }).then(() => {
      if (audios.length > 0) {
        audios.forEach((audio) => {
          let fileName = audio.track_location.replace("uploads/audios/","")
          const audioUrl = `https://api.mccullaghmusic.com/api/v1/audio/${fileName}`
          console.log(fileName);
          
          const fetchAndDownloadAudio = async () => {
            try {
              const response = await fetch(audioUrl);
              if (!response.ok) {
                console.error("Failed to fetch audio:", response.status);
                return;
              }
              
              const blob = await response.blob();

              if (blob) {
                setAudioBlob(blob);
                console.log(audioBlob);
                // Download the audio file immediately after fetching
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                let newName = fileName.replace(/-/g, ' ');
                newName = newName.replace(".mp3", "-[Mccullaghmusic].mp3")
                a.href = downloadUrl;
                a.download =  newName; // Use the title or any relevant information
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              } else {
                console.error("Empty blob received.");
              }
            } catch (error) {
              console.error("Error fetching audio:", error);
            }
          };

          fetchAndDownloadAudio();
        });
      } else {
        console.error("No audio tracks found.");
      }
    });
  };


  return (
    <div className="relative bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700">
      <Link
        onClick={() => {
          localStorage.setItem("music-id", id);
        }}
        to={details_link}
      >
        <img
          src={"https://api.mccullaghmusic.com/" + image}
          alt="pro"
          className="rounded-t-lg h-[280px] w-full object-cover brightness-75"
        />
      </Link>
      <Link
        onClick={() => {
          localStorage.setItem("music-id", id);
        }}
        to={details_link}
        className="py-2 text-center"
      >
        <span className="text-xs text-gray-500">{artist_name}</span>
        <h5 className="text-gray-700 h-10 font-bold dark:text-white">
          {newTitle}
        </h5>
      </Link>

      <div className="flex bg-white-200 p-1 jusstify-space-betweern">
        <button
          onClick={(event) => handleDownload(event, id)} 
          type="button"
          className="text-white py-2 flex bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-small rounded-lg text-sm px-2 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
        >
          Download
        </button>
        <button
          type="button"
          className="text-gray bg-white rounded-lg border border-gray-200  py-3 flex  hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-1 py-2.5 mr-1 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
        >
          <MdDownload className="text-lg" />
          <span className="" style={{fontSize: "10px", }}>{downloads}</span>
        </button>
        <button
          type="button"
          className="text-gray bg-white rounded-lg border border-gray-200  py-3 flex  hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
        >
          <MdRemoveRedEye className="text-lg" />
          <span className="ml-1" style={{fontSize: "10px", }}>{views}</span>
        </button>
      </div>
    </div>
  );
};
