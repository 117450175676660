import { useState, useEffect } from "react";
import { MusicT } from "../../types";
import { MusicCard } from ".";
// Define a type that matches the structure of the data returned from the API

export const SearchCard = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<MusicT[]>([]);
  const [error, setError] = useState<string | null>(null); // State to store error messages

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (query.trim() === "") {
        setResults([]); // Clear the results if the query is empty
      } else {
        try {
          const response = await fetch(
            `https://api.mccullaghmusic.com/api/v1/search/music?query=${query}`
          );
          if (response.ok) {
            const data = await response.json();

            setResults(data.data);
            setError(null); // Clear any previous errors
          } else {
            // Handle non-JSON response (e.g., HTML error page)
            setError(`Error: ${response.status} - ${response.statusText}`);
          }
        } catch (error) {
          setError("An error occurred while fetching data.");
        }
      }
    };

    // Delay the API request to reduce the number of requests made while typing
    const delayTimer = setTimeout(fetchData, 500);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [query]);


  return (
    <div>
      <div>
        {/* Rest of your component code... */}
        {error && <div>Error: {error}</div>}
      </div>
      <form
        method="get"
        style={{ maxWidth: "700px", margin: "1rem auto" }}
        className="m-3"
      >
        <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            name="search"
            type="search"
            id="default-search"
            className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
            placeholder="Search your best music..."
            value={query}
            onChange={handleInputChange}
            required
          />
        </div>
      </form>

      {results.length > 0 && (
        <div className="py-6 px-3 sm:px-16 lg:px-20 bg-red-50">
          <h3 className="capitalize text-center text-gray-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold">
            Search Results:
          </h3>
          <div className="w-full grid gap-y-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-3 lg:grid-cols-4 lg:gap-x-5 px-[1.5rem] sm:px-6 py-8">
            {results.map((music) => (
              <MusicCard
                key={music.id}
                id={music.id}
                details_link={"../play/" + music.title_link}
                title={music.track_title}
                downloads={music.downloads}
                artist_name={music.artist_name}
                views={music.views}
                audios={music.audios}
                image={music.images?.map((image) => image.artwork_location)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
