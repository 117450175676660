import { ChangeEvent, FormEvent, useState } from "react";
import { AxiosApi } from "../../config";
import {  toast } from "react-toastify";

export const Contact = () => {
  // state for the form data
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    phoneNumber: "",
    message: "",
  }) 
  const [isSuccess, setIsSuccess] = useState(false); 

  const formDataChange = <T extends HTMLTextAreaElement | HTMLInputElement>(
    event: ChangeEvent<T>
  ) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSendMessage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (formDataValidation()) {
        const { data } = await AxiosApi.post("/contact", formData);
       setIsSuccess(true)
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formDataValidation = () => {
    const { fullName, email, subject, message } = formData;
    if (!fullName) {
      toast.error("Please enter your full name");
      return false;
    } else if (!email) {
      toast.error("Please enter your email");
      return false;
    } else if (!subject) {
      toast.error("Please enter subject");
      return false;
    } else if (!message) {
      toast.error("Please enter a message");
      return false;
    } else return true;
  };

  return (
    <div
      style={{ maxWidth: "1000px", margin: "auto", marginTop: "4rem" }}
      className="xl:px-20 2xl:px-32, p-6 border border-gray-200"
    >
      {!isSuccess ? (
        <div>
          <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Contact McCullagh Music
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              We'd love to hear from you! Whether you have a question, feedback,
              or you simply want to get in touch with us, 
            </p>
          </div>
          <form onSubmit={handleSendMessage}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label
                  id="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Full name
                </label>
                <input
                  type="text"
                  id="Full name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Full name"
                  required
                  name="fullName"
                  value={formData.fullName}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="phoneNumber"
                  required
                  value={formData.phoneNumber}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="Subject"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subjext"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Subject"
                  required
                  name="subject"
                  value={formData.subject}
                  onChange={formDataChange}
                />
              </div>
            </div>

            <div>
              <label
                id="Additional Details"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Additional Details
              </label>
              <textarea
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-primary resize-none"
                id="Additional-Details"
                placeholder="Any specific features or preferences."
                cols={20}
                rows={7}
                name="message"
                required
                value={formData.message}
                onChange={formDataChange}
              />
            </div>
            <button
              type="submit"
              className="mt-5 text-white bg-red-800 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">
            Message Sent Successfully
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Thank you for reaching out to us! Your message has been successfully
            received. We'll review it and get back to you soon. In the meantime,
            feel free to explore our website or check out our latest updates. 
          </p>
        </div>
      )}
    </div>
    // <div className="px-3 w-full sm:px-16 lg:px-32 py-6 lg:py-14">
    //   <div className="flex w-full place-content-center">
    //     <form
    //       className="bg-white rounded-md py-6 px-5 lg:px-10"
    //       onSubmit={handleSendMessage}
    //     >
    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="username"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Username
    //         </label>

    //         <input
    //           type="text"
    //           id="username"
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-primary"
    // placeholder="Username"
    // name="username"
    // value={formData.username}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="email"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Email
    //         </label>

    //         <input
    //           type="email"
    //           id="email"
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-primary"
    //           placeholder="Email Address"
    // name="email"
    // value={formData.email}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="subject"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Subject
    //         </label>

    //         <input
    //           type="text"
    //           id="subject"
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-primary"
    //           placeholder="Subject"
    // name="subject"
    // value={formData.subject}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="message"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Message
    //         </label>

    //         <textarea
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-primary resize-none"
    //           id="message"
    //           placeholder="Type your message"
    //           cols={20}
    //           rows={7}
    // name="message"
    // value={formData.message}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mt-5">
    //         <button className="tracking-widest text-white bg-primary focus:ring-4 focus:outline-none focus:ring-red-300 font-semibold rounded text-sm px-5 w-1/2 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
    //           Send Message
    //         </button>
    //       </div>
    //     </form>
    //   </div>

    //   <ToastContainer
    //     position="top-center"
    //     autoClose={5000}
    //     hideProgressBar={false}
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     limit={1}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    //     theme="colored"
    //   />
    // </div>
  );
};
