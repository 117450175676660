import { Link } from "react-router-dom";
import { mccullaghLogo} from "../../assets";
import { useEffect, useState } from "react";
import { SearchCard } from "../../components/cards";
import { MusicOptions } from "../../types";

export const ClientNavbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  // Function to close the menu
  const closeMenu = () => {
    setShowMenu(false);
  };
  const handleLinkClick = (musicType) => {
    // Close the menu
    closeMenu();
    // Set the selected music type in localStorage
    localStorage.setItem("music-type", musicType);
    useEffect(() => {
      // This effect will run whenever the URL changes
      window.addEventListener("popstate", handleURLChange);

      // Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("popstate", handleURLChange);
      };
    }, []);

    const handleURLChange = () => {
      // Get the current URL from the window.location object
       window.location.href;
    };
    
  };
  console.log(MusicOptions);
  
  return (
    <>
      <nav
        style={{ zIndex: "10000" }}
        className="bg-white dark:bg-red-900 sticky w-full  top-0 left-0 border-b border-red-200 dark:border-red-600"
      >
        <div className="max-w-screen-xl flex z-51 flex-wrap items-center justify-between mx-auto p-4">
          <div className="flex ">
            <img
              src={mccullaghLogo}
              className="h-8 lg:w-full object-contain "
              alt="Mccullagh Music logo"
            />
            <h1 className="w-20">Mccullagh Music</h1>
          </div>

          <div className="flex md:order-2">
            <Link
              to={"https://2022-2023.mccullaghmusic.com/"}
              className="flex items-center justify-center text-white bg-red-800 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-2 sm:px-4 py-2 text-center mr-1 sm:mr-3 md:mr-0 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Old-Music
            </Link>

            <button
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-red-800 rounded-lg md:hidden hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-200 dark:text-red-400 dark:hover:bg-red-700 dark:focus:ring-red-600"
              onClick={() => setShowMenu((prev) => !prev)}
            >
              <span className="sr-only">Main Menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

          <div
            className={`items-center justify-between w-full ${
              showMenu ? "fixed top-0 left-0" : "hidden"
            } md:flex md:w-auto md:order-1`}
            id="navbar-sticky"
          >
            {/* Close button */}
            <button
              type="button"
              className="md:hidden absolute top-4 right-4"
              onClick={closeMenu}
            >
              <svg
                className="w-5 h-5 text-red-800 hover:text-red-700 dark:text-red-400 dark:hover:text-red-600"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>

            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-red-100 rounded-lg bg-red-50 w-[96%] mx-auto md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-red-800 md:dark:bg-red-900 dark:border-red-700">
              <li>
                <Link
                  to="/"
                  className="block py-2 pl-3 pr-4 text-white bg-red-700 rounded md:bg-transparent md:text-red-700 md:p-0 md:dark:text-red-800"
                  aria-current="page"
                  onClick={closeMenu} // Close the menu when the Home link is clicked
                >
                  Home
                </Link>
              </li>

              <li className="relative group cursor-pointer">
                <button className="w-full flex items-center justify-between mt-1 sm:mt-0 py-2 pl-3 pr-4 text-red-900 rounded group-hover:bg-red-100 md:group-hover:bg-transparent md:group-hover:text-red-700 md:p-0 md:dark:group-hover:text-red-800 dark:text-white dark:group-hover:bg-red-700 dark:group-hover:text-white md:dark:group-hover:bg-transparent dark:border-red-700">
                  <span>music</span>
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                <ul className="hidden absolute w-full md:w-fit md:-left-1/2 top-full bg-white rounded-b px-5 py-6 group-hover:grid gap-y-2">
                  {MusicOptions.music.map((type) => (
                    
                    <li key={type}>
                      <Link
                        to={`music/${type}`}
                        className="block py-1 pl- text-red-700 md:p-0 md:dark:text-red-800"
                        aria-current="page"
                        onClick={() => handleLinkClick(type)}
                        // Close the menu when the Dancehall link is clicked
                      >
                        {type}
                      </Link>
                    </li>
                  ))}

                  <li>
                    <Link
                      to="/music/afro"
                      className="block py-1 pl-3 text-red-700 md:p-0 md:dark:text-red-800"
                      aria-current="page"
                      onClick={() => handleLinkClick("afro")}
                    >
                      Afro
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/music/passada"
                      className="block py-1 pl-3 text-red-700 md:p-0 md:dark:text-red-800"
                      aria-current="page"
                      onClick={() => handleLinkClick("passada")}
                    >
                      Passada
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/music/hip-hop"
                      className="block py-1 pl-3 text-red-700 md:p-0 md:dark:text-red-800"
                      aria-current="page"
                      onClick={() => handleLinkClick("hip-hop")}
                    >
                      Hip-Hop
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/music/drill"
                      className="block py-1 pl-3 text-red-700 md:p-0 md:dark:text-red-800"
                      aria-current="page"
                      onClick={() => handleLinkClick("drill")}
                    >
                      Drill
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Other menu items */}

              <li>
                <Link
                  to="/trending"
                  onClick={closeMenu}
                  className="block py-2 pl-3 pr-4 text-red-900 rounded hover:bg-red-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-800 dark:text-white dark:hover:bg-red-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-red-700"
                >
                  Trending
                </Link>
              </li>
              <li>
                <Link
                  to="/instruments"
                  onClick={closeMenu}
                  className="block py-2 pl-3 pr-4 text-red-900 rounded hover:bg-red-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-800 dark:text-white dark:hover:bg-red-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-red-700"
                >
                  Instruments
                </Link>
              </li>
              <li>
                <Link
                  onClick={closeMenu}
                  to="/about"
                  className="block py-2 pl-3 pr-4 text-red-900 rounded hover:bg-red-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-800 dark:text-white dark:hover:bg-red-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-red-700"
                >
                  About
                </Link>
              </li>

              <li>
                <Link
                  to="/contact"
                  onClick={closeMenu}
                  className="block py-2 pl-3 pr-4 text-red-900 rounded hover:bg-red-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-800 dark:text-white dark:hover:bg-red-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-red-700"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <SearchCard />
    </>
  );
};
