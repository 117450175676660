import { Link } from 'react-router-dom';
import { useDeleteMutation, useFetch } from '../../hooks';
import { MusicT } from '../../types';
import { LargeSpinner } from '../../components';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { useState } from 'react';
import { DeleteModal } from '../../components/modals';

export const AllMusic = () => {
     const query = useFetch<MusicT>({
       cacheId: "all",
       endpoint: "all",
     });
     const [showModal, setShowModal] = useState(false);
      const [selectedFaqId, setSelectedFaqId] = useState<number | null>(null);

      const deleteMutation = useDeleteMutation({
        endpoint: "/music",
        cacheId: "music",
      });

      const deleteFaq = () => {
        deleteMutation.mutate(selectedFaqId!);
        setShowModal(false);
      };
     
  return (
    <>
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          {query.isSuccess ? (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <>
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Title
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <div className="flex items-center">
                        Artist
                        <a href="#">
                          <svg
                            className="w-3 h-3 ml-1.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <div className="flex items-center">
                        Downloads
                        <a href="#">
                          <svg
                            className="w-3 h-3 ml-1.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <div className="flex items-center">
                        Audio
                        <a href="#">
                          <svg
                            className="w-3 h-3 ml-1.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                          </svg>
                        </a>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3">
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {query.data.map((music) => (
                    <tr key={music.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {music.track_title.slice(0, 20)}
                      </th>
                      <td className="px-6 py-4">{music.artist_name}</td>
                      <td className="px-6 py-4">{music.downloads}</td>
                      <td className="px-6 py-4">{music.other_type}</td>
                      <td
                        className="px-6 py-4 "
                        onClick={() => {
                          localStorage.setItem("update-id", `${music.id}`);
                        }}
                      >
                        <Link
                          to="/admin/update"
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          Edit
                        </Link>
                      </td>
                      <td>
                        <div className="flex items-center justify-center gap-x-2 mt-4">
                          {/* <button className="bg-green-600 text-white flex items-center gap-x-1 font-medium dark:text-green-500 hover:text-white hover:bg-green-500 rounded-lg p-2">
          <MdOutlineEdit className="text-xl" />
          edit
        </button> */}
                          <button
                            onClick={() => {
                              setSelectedFaqId(music.id);
                              setShowModal(true);
                            }}
                            className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
                          >
                            <MdOutlineDeleteOutline className="text-xl" />
                            delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            </table>
          ) : (
            <> There was an error to fetch dats</>
          )}
          <DeleteModal
            modalTitle="Delete Music"
            showModal={showModal}
            closeModal={() => setShowModal(false)}
            deleteFn={deleteFaq}
          />
        </div>
      )}
    </>
  );
}
