
import { Helmet } from "react-helmet-async";

const MetaChanger = ({
  title,
  description,
  OG_title,
  OG_description,
  OG_image,
  OG_audio,
}) => {
const generateOGImageURL = (OG_image) => {
  // Fetch image URL from CMS or API based on contentID
  const imageURL = `https://api.mccullaghmusic.com/${OG_image}`;
  return imageURL;
};
const url = generateOGImageURL(OG_image);
  

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={OG_title} />
      <meta property="og:audio:type" content="audio/mpeg" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={url} />
      <meta data-rh="true" property="og:audio" content={OG_audio} />
      <meta property="og:description" content={OG_description} />
    </Helmet>
  );
};

export default MetaChanger;
