import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { register } from "swiper/element/bundle";
import { HelmetProvider } from "react-helmet-async";
// register the swiper web componets
register();

const root = createRoot(document.getElementById("root")!);

root.render(
  <HelmetProvider>
    <StrictMode>
      <App />
    </StrictMode>
  </HelmetProvider>
);
