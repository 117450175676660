import {  mccullaghLogo } from "../../assets";

export const AboutCompany = () => {
  
  return (
    <div>
      <div className="max-h-[500px] relative">
        <div className="absolute bg-green-500 bg-opacity-40 w-full h-full text-gray-200 max-h-[500px] flex flex-col justify-center lg:pl-10">
          <h1 className="px-4 text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-bold">
            Elevating Malawi's Soundscape,
          </h1>
          <h1 className="px-4 text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-bold">
            Sharing Music Everywhere,
          </h1>
        </div>
        <img
          className="w-full max-h-[300px] object-cover"
          src={mccullaghLogo}
          alt="music"
        />
      </div>
      <div
        style={{ maxWidth: "1000px", margin: "1rem auto" }}
        className="block  w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 "
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          About McCullagh Music
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          At McCullagh Music, we are passionate about the vibrant and diverse
          music scene in Malawi. Our mission is to be the bridge that connects
          Malawian artists and their exceptional music with the world.
        </p>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Who We Are
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          We are a dedicated team of music enthusiasts, promoters, and digital
          marketing experts who have come together to celebrate the rich
          tapestry of musical talent that Malawi has to offer. With a deep
          appreciation for the unique rhythms and melodies that define Malawian
          music, we are committed to promoting and showcasing the incredible
          talents of Malawian artists.
        </p>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          What We Do
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          Our platform serves as a hub for both established and emerging
          musicians to share their music with a global audience. We understand
          the importance of visibility in the digital age, which is why we offer
          a comprehensive range of services that include music uploads, social
          media promotion, and marketing strategies tailored to each artist's
          unique style and needs.
        </p>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Our Mission
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          <ul>
            <ol>
              <strong>Promotion:</strong> We aim to bring Malawian music to the
              forefront of the global music scene, ensuring that the world
              recognizes and appreciates the talent that resides in this
              beautiful nation.
            </ol>
            <ol>
              <strong>Empowerment:</strong> We empower artists by providing them
              with the tools, exposure, and support they need to thrive in the
              competitive music industry.
            </ol>
            <ol>
              <strong>Diversity:</strong> Malawi's music scene is as diverse as
              its culture. We celebrate this diversity by promoting a wide range
              of genres, from traditional to contemporary, ensuring that there's
              something for everyone to enjoy.
            </ol>
          </ul>
        </p>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Join Us on this Musical Journey
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          Whether you're an artist looking to share your music with the world or
          a music lover eager to discover the next big sensation from Malawi,
          McCullagh Music welcomes you to join us on this exciting musical
          journey. Together, we can create a harmonious world where Malawian
          music resonates with audiences far and wide.
        </p>
        
      </div>
    </div>
  );
};
