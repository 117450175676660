
export const Nodata = () => {
  return (
    <div className="w-full p-6 bg-white text-center border border-green-200 rounded-lg shadow dark:bg-green-800 dark:border-green-700">
      <a href="#">
        <h5 className="mb-2 text-2xl text-center font-bold tracking-tight text-green-900 dark:text-white">
          No Music visit{" "}
          <a href="https://2022-2023.mccullaghmusic.com/">
            https://2022-2023.mccullaghmusic.com/
          </a>
        </h5>
      </a>
      <p className="mb-3 text-center font-normal text-green-700 dark:text-green-400">
        Please check back later. We appreciate your understanding.
      </p>
    </div>
  );
}
