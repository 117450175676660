import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ModalContainer } from "../ModalContainer";
import {  ModalContainerT } from "../../types";
import { useUploadMutation } from "../../hooks";
import { LargeSpinner } from "..";
import { UserT } from "../../types/UserT";

export const AddUserModal = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  const [userData, setUserData] = useState<UserT>({
    name: "",
    email: "",
    password: "",
  });

  const mutation = useUploadMutation<UserT>({
    endpoint: "/register",
    cacheId: "register",
  });

  const handleUploadBlog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate({
      name: userData.name,
      email: userData.email,
      password: userData.password,
    });
  };

  const userDataChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      props.closeModal();
      location.reload();
    }
  }, [mutation, props]);

  return (
    <ModalContainer
      modalTitle="Add New User"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>Blog posted</h3>
      ) : null}
      <form className="py-3 px-5" onSubmit={handleUploadBlog}>
        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="name"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Name
          </label>

          <input
            type="text"
            id="name"
            name="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="name"
            value={userData.name}
            onChange={userDataChange}
          />
        </div>

        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="email"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Email
          </label>

          <input
            type="text"
            id="email"
            name="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="email"
            value={userData.email}
            onChange={userDataChange}
          />
        </div>
        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="password"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Password
          </label>

          <input
            type="text"
            id="password"
            name="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="password"
            value={userData.password}
            onChange={userDataChange}
          />
        </div>
        <div>
          <button
            disabled={mutation.isPending}
            className="w-full bg-primary py-2 rounded text-white tracking-wider"
          >
            Add User
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};
