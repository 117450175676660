import {
  MusicSection,
} from "../../components";

export const LandingPage = () => {
  return (
    <div>
      

      <MusicSection />
    </div>
  );
};
