import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ModalContainer } from "../ModalContainer";
import { ModalContainerT } from "../../types";
import { useUploadMutation } from "../../hooks";
import { LargeSpinner } from "..";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { AboutData } from "../../types/AboutT";

interface QuillEditorProps {
  onDescriptionChange: (value: string) => void;
}

class QuillEditor extends React.Component<QuillEditorProps, { text: string }> {
  modules: { toolbar: (string[] | { list: string }[])[] };
  formats: string[];
  constructor(props: QuillEditorProps) {
    super(props);
    this.modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
    };
    this.formats = [
      "bold",
      "italic",
      "underline",
      "strike",
      "list",
      "bullet",
      "link",
    ];
    this.state = { text: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value: string) {
    this.setState({ text: value });
    this.props.onDescriptionChange(value); // Pass the description value to the parent component
  }
  render() {
    return (
      <ReactQuill
        value={this.state.text}
        onChange={this.handleChange}
        modules={this.modules}
        formats={this.formats}
      />
    );
  }
}

export const AddAboutModal = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  const [aboutData, setAboutData] = useState<AboutData>({
    title: "",
    description: "",
    id: null,
  });

  const mutation = useUploadMutation<AboutData>({
    endpoint: "/about",
    cacheId: "about",
  });

  const handleUploadAbout = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate({
    id: null,
      title: aboutData.title,
      description: aboutData.description,
    });
  };

  const aboutDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setAboutData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDescriptionChange = (value: string) => {
    setAboutData((prevData) => ({ ...prevData, description: value }));
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      props.closeModal();
      location.reload();
    }
  }, [mutation, props]);


  return (
    <ModalContainer
      modalTitle="Add About"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>About added</h3>
      ) : null}
      <form
        style={{ width: "1200px" }}
        className="py-3 px-5"
        onSubmit={handleUploadAbout}
      >
        <div className="mb-4 w-full">
          <label
            htmlFor="title"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Title
          </label>

          <input
            type="text"
            id="title"
            name="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="Title"
            value={aboutData.title}
            onChange={aboutDataChange}
          />
        </div>

        <div className="mb-4 w-full">
          <label
            htmlFor="description"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Description
          </label>

          <QuillEditor onDescriptionChange={handleDescriptionChange} />
        </div>


        <div>
          <button className="w-full bg-primary py-2 rounded text-white tracking-wider">
            Upload Product
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};
