import axios from "axios";

type UrlObject = {
  link: string;
};

export const Increment = ({ link }: UrlObject): Promise<boolean> => {
  // Return a promise to allow chaining with the .then() method
  return new Promise((resolve) => {
    // Update views only if it hasn't been updated before in this session

    axios
      .put(link)
      .then(() => {
        resolve(true); // Resolve the promise with true
      })
      .catch(() => {
        resolve(false); // Resolve the promise with false
      });
  });
};
