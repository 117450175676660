export const ClientFooter = () => {
  return (
    <footer className="bg-red-800 dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
              Mccullagh Music
            </h2>
            <p className="text-white">
              Elevating Malawi's Soundscape, Sharing Music Everywhere, We are
              passionate about the vibrant and diverse music scene in Malawi.
              Our mission is to be the bridge that connects Malawian artists and
              their exceptional music with the world.
            </p>
            {/* <ul className="text-white font-medium">
              <li className="mb-4">
                <a href="#" className=" hover:underline">
                  About
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Careers
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Brand Center
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Blog
                </a>
              </li>
            </ul> */}
          </div>

          <div>
            <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
              Contact Us
            </h2>
            <ul className="text-white font-medium">
              <li className="mb-4">
                <a href="https://tiktok.com/@mccullaghmusicpro">TikTok</a>
              </li>
              <li className="mb-4">
                <a href="https://twitter.com/Mccullaghmusic">Twitter</a>
              </li>
              <li className="mb-4">
                <a
                  href="https://wa.me/++265995196104"
                  className="hover:underline"
                >
                  Whatsapp us
                </a>
              </li>
              <li className="mb-4">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Mccullaghmusic/"
                  className="hover:underline"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
              Others Links
            </h2>
            <ul className="text-white font-medium">
              <li className="mb-1">
                <a href="trending" className="hover:underline">
                  Trending
                </a>
              </li>
              <li className="mb-1">
                <a href="/instruments" className="hover:underline">
                  Instruments
                </a>
              </li>
              <li className="mb-1">
                <a href="/product" className="hover:underline">
                  Products
                </a>
              </li>
              <li className="mb-1">
                <a href="/about" className="hover:underline">
                  About
                </a>
              </li>
              <li className="mb-4">
                <a href="/contact" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
              Location
            </h2>
            <h3 className="text-white">Malawi</h3>
            <p className="text-white">
              Zomba
            </p>
          </div>
        </div>
      </div>
      <div className="px-4 py-6 w-fill bg-red-900 md:flex md:items-center md:justify-center">
        <span className="text-sm text-white sm:text-center">
          {new Date().getFullYear()} <a href="">Mccullagh Music</a>. All Rights
          Reserved.
        </span>
        <div className="flex text-white px-2 mt-4 space-x-5 sm:justify-center md:mt-0">
          <p>Powered by</p>
          <a href="https://infi-tech.net" className="ml-2 hover:underline">
            INFI-TECH.NET
          </a>
        </div>
      </div>
    </footer>
  );
};
