import { adversiting } from "../../assets";
import { LargeSpinner } from "../../components";
import { MusicCard, Nodata } from "../../components/cards";
import { useFetch } from "../../hooks"
import { MusicT } from "../../types"

export const Trending = () => {
    const query = useFetch<MusicT>({
      endpoint: "trending",
      cacheId: "trending",
    });
     console.log(query.data?.map((data)=>(
        data.artist_name
     )));
  return (
    <div className="py-6 px-3 sm:px-16 lg:px-20 bg-red-50">
      <h3 className="capitalize text-center text-gray-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold">
        Trending now
      </h3>
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess ? (
            <div>
              {query.data.length ? (
                <div className="w-full grid gap-y-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-3 lg:grid-cols-4 lg:gap-x-5 px-[1.5rem] sm:px-6 py-8">
                  <div className="relative bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700">
                    <div>
                      <img
                        src={adversiting}
                        alt="pro"
                        className="rounded-t-lg h-[280px] w-full object-cover brightness-75"
                      />
                      <h4> Music uploading price</h4>
                    </div>
                  </div>
                  {query.data.map((music) => (
                    <MusicCard
                      key={music.id}
                      id={music.id}
                      details_link={"../play/" + music.title_link}
                      title={music.track_title}
                      downloads={music.downloads}
                      artist_name={music.artist_name}
                      views={music.views}
                      audios={music.audios}
                      image={music.images?.map(
                        (image) => image.artwork_location
                      )}
                    />
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          ) : (
            <div> There is error to load data</div>
          )}
        </div>
      )}
    </div>
  );
}
