import { toast } from "react-toastify";
import { AxiosApi } from "../../config";
import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { useFetch } from "../../hooks";
import { MusicT } from "../../types";

export const UpdateMusic = () => {
  const [formData, setFormData] = useState({
    track_title: "",
    artist_name: "",
    other_type: "",
    track_details: "",
    track_type: "default",
    home: "-",
    downloads: 0,
    views: 0,
    trending: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);

  // Fetch the initial data
  // Fetch the initial data
  const query = useFetch<MusicT>({
    endpoint: `music/${localStorage.getItem("update-id")}`,
    cacheId: `music-${localStorage.getItem("update-id")}`,
  });

  useEffect(() => {
    if (query.data) {
      // Update the form data with the fetched data
      const musicData = Array.isArray(query.data) ? query.data[0] : query.data;
      setFormData({
        track_title: musicData.track_title,
        artist_name: musicData.artist_name,
        other_type: musicData.other_type,
        track_details: musicData.track_details,
        track_type: musicData.track_type,
        home: musicData.home,
        views: musicData.views || 0,
        trending: musicData.trending,
        downloads: musicData.downloads,
      });
    }
  }, [query.data]);

  const formDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? event.target.CDATA_SECTION_NODE : value,
    }));
  };

  const handleSendMessage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (formDataValidation()) {
        const { data } = await AxiosApi.put(
          `/update/music/${localStorage.getItem("update-id")}`,
          formData
        );
        setIsSuccess(true);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formDataValidation = () => {
    const { track_title, artist_name, track_type, other_type } = formData;
    if (!track_title) {
      toast.error("Please enter your Title");
      return false;
    } else if (!artist_name) {
      toast.error("Please enter your name");
      return false;
    } else if (track_type === "default") {
      toast.error("Please select a track type");
      return false;
    } else if (!other_type) {
      toast.error("Please enter an other_type");
      return false;
    } else return true;
  };

  // Store the form data in local storage
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  // Retrieve the form data from local storage
  useEffect(() => {
    const storedData = localStorage.getItem("formData");
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);

  return (
    <div
      style={{ maxWidth: "1000px", margin: "4rem auto" }}
      className="xl:px-20 2xl:px-32, p-6 border border-gray-200"
    >
      {!isSuccess ? (
        <div>
          <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Update: {formData.track_title}
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400"></p>
          </div>
          <form onSubmit={handleSendMessage}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label
                  id="track_title"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="track_title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="track_title"
                  required
                  name="track_title"
                  value={formData.track_title}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Artist name
                </label>
                <input
                  type="text"
                  id="artist_name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="artist_name"
                  name="artist_name"
                  required
                  value={formData.artist_name}
                  onChange={formDataChange}
                />
              </div>
            </div>
            <div className="flex">
              <div className="mb-1 w-full">
                <label
                  htmlFor="track_type"
                  className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
                >
                  Track Type
                </label>

                <select
                  id="track_type"
                  name="track_type"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
                  onChange={formDataChange}
                  value={formData.track_type || "default"}
                >
                  <option value={"default"} disabled>
                    __select__
                  </option>
                  <option value="Gospel">Gospel</option>

                  <option value="Praise">Praise</option>

                  <option value="Dancehall">Dancehall</option>

                  <option value="Hip-Hop">Hip-Hop</option>
                  <option value="Raggae">Raggae</option>
                  <option value="Poem">Poem</option>
                  <option value="RNB">RNB</option>
                  <option value="Amapiano">Amapiano</option>
                  <option value="Bongo-Flava">Bongo-Flava</option>
                  <option value="Gqom">Gqom</option>

                  <option value="Slowjams">SlowJams</option>
                  <option value="Afro">Afro</option>
                  <option value="Trap">Trap</option>
                  <option value="House">House</option>
                  <option value="Passada">Passada</option>
                  <option value="Rumba">Rumba</option>
                  <option value="Local">Local</option>
                  <option value="Grime">Grime</option>
                  <option value="Drill">Drill</option>
                  <option value="Instruments">Instruments</option>
                  <option value="Riddim">Riddim</option>

                  <option value="Highlife">Highlife</option>
                  <option value="Kwaito">Kwaito</option>
                  <option value="Coupé-décalé">Coupé-décalé</option>
                  <option value="Makossa">Makossa</option>
                  <option value="Electronic-dance-music">
                    Electronic-Dance-Music
                  </option>
                  <option value="Latin">Latin</option>
                  <option value="K-pop">K-Pop</option>
                  <option value="Country">Country</option>
                  <option value="Classical">Classical</option>
                </select>
              </div>
              <div className="mb-1 w-full">
                <label
                  htmlFor="other_type"
                  className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
                >
                  Other Type
                </label>

                <select
                  id="other_type"
                  name="other_type"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
                  onChange={formDataChange}
                  value={formData.other_type || "default"}
                >
                  <option value={"default"} disabled>
                    __select__
                  </option>
                  <option value="music">music</option>
                  <option value="instruments">Instruments</option>
                </select>
              </div>
            </div>
            <div className="flex">
              <div className="mb-1 w-full">
                <label
                  htmlFor="trending"
                  className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
                >
                  Trending
                </label>

                <select
                  id="trending"
                  name="trending"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
                  onChange={formDataChange}
                  value={formData.trending || "default"}
                >
                  <option value={"default"} disabled>
                    __select__
                  </option>
                  <option value="trending">Yes</option>

                  <option value="no">No</option>
                </select>
              </div>
              <div className="mb-1 w-full">
                <label
                  htmlFor="home"
                  className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
                >
                  Home
                </label>

                <select
                  id="home"
                  name="home"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
                  onChange={formDataChange}
                  value={formData.home || "default"}
                >
                  <option value={"default"} disabled>
                    __select__
                  </option>
                  <option value="home">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>

            
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label
                  id="downloads"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  downloads
                </label>
                <input
                  type="text"
                  id="downloads"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="downloads"
                  required
                  name="downloads"
                  value={formData.downloads}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Views
                </label>
                <input
                  type="text"
                  id="views"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="views"
                  name="views"
                  required
                  value={formData.views}
                  onChange={formDataChange}
                />
              </div>
            </div>
            <div>
              <label
                id="track_details"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Track details
              </label>
              <input
                type="text"
                id="track_details"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="track_details"
                required
                name="track_details"
                value={formData.track_details}
                onChange={formDataChange}
              />
            </div>
            <button
              type="submit"
              className="mt-5 text-white bg-red-800 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">
            Message Sent Successfully
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Song has been successfully updated
          </p>
        </div>
      )}
    </div>
  );
};
