import { useState, useEffect } from "react";
import { useFetch } from "../../hooks";
import { LargeSpinner } from "../../components";
import { MusicCard, Nodata } from "../../components/cards";
import { MusicT } from "../../types";
import { MdDownload, MdRemoveRedEye } from "react-icons/md";
import { Increment } from "../../hooks/Increment";
import { adversiting } from "../../assets";
import { useParams } from "react-router-dom";
import MetaChanger from "../../components/cards/MetaChanger";
import ShareAndCopyButtons from "../../components/cards/ShareAndCopyButtons";

export const Play = () => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const { song } = useParams();
  const url = "https://api.mccullaghmusic.com/";
  const query = useFetch<MusicT>({
    endpoint: `play/${song}`,
    cacheId: `music-${song}`,
  });

  console.log(song);

  const musicData = Array.isArray(query.data) ? query.data[0] : query.data;
  const totalTracks = musicData?.audios?.length || 0;
  const musicId = musicData?.id;
  useEffect(() => {
    const audioElement = document.getElementById(
      "audio-element"
    ) as HTMLAudioElement | null;
    if (isPlaying && audioElement) {
      audioElement.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else if (audioElement) {
      audioElement.pause();
    }
  }, [isPlaying, currentTrackIndex]);

  const playPauseToggle = () => {
    handleVievsClick();
    setIsPlaying(!isPlaying);
  };

  const playNextTrack = () => {
    if (musicData && musicData.audios && totalTracks > 0) {
      setCurrentTrackIndex((currentTrackIndex + 1) % totalTracks); // Loop back to the first track if at the last.
      setIsPlaying(true);
    }
  };

  const playPreviousTrack = () => {
    if (musicData && musicData.audios && totalTracks > 0) {
      setCurrentTrackIndex((currentTrackIndex - 1 + totalTracks) % totalTracks); // Loop to the last track if at the first.
      setIsPlaying(true);
    }
  };
  const relate = useFetch<MusicT>({
    endpoint: `music-type/${musicData?.track_type}`,
    cacheId: `music-${musicData?.track_type}`,
  });
  // updating views
  const [hasUpdatedViews, setHasUpdatedViews] = useState(false);
  const [hasUpdateDownloads, setHasUpdateDownloads] = useState(false);
  const handleVievsClick = () => {
    // Check if views have been updated for this music ID
    const hasUpdated = localStorage.getItem("view") === `${musicId}`;
    if (!hasUpdated && !hasUpdatedViews) {
      // Update views only if it hasn't been updated before in this session
      Increment({
        link: `${url}api/v1/increment/${musicId}/views`,
      }).then((updated) => {
        if (updated) {
          localStorage.setItem("view", `${musicId}`);
          setHasUpdatedViews(true);
        }
      });
    }
  };

  const handleDownloadClick = () => {
    const hasUpdated = localStorage.getItem("downloads") === `${musicId}`;
    if (!hasUpdated && !hasUpdateDownloads) {
      Increment({
        link: `${url}api/v1/increment/${musicId}/downloads`,
      }).then((updated) => {
        if (updated) {
          setHasUpdateDownloads(true);
          localStorage.setItem("downloads", `${musicId}`);
          // Create an invisible anchor element for each audio track
          musicData?.audios?.forEach((music) => {
            let fileName = music.track_location.replace("uploads/audios/","")
          const audioUrl = `https://api.mccullaghmusic.com/api/v1/audio/${fileName}`
          console.log(audioBlob);
          
          const fetchAndDownloadAudio = async () => {
            try {
              const response = await fetch(audioUrl);
              if (!response.ok) {
                console.error("Failed to fetch audio:", response.status);
                return;
              }
              
              const blob = await response.blob();

              if (blob) {
                setAudioBlob(blob);
                console.log(response.status);
                // Download the audio file immediately after fetching
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                let newName = fileName.replace(/-/g, ' ');
                newName = newName.replace(".mp3", "-[Mccullaghmusic].mp3")
                a.href = downloadUrl;
                a.download =  newName; // Use the title or any relevant information
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              } else {
                console.error("Empty blob received.");
              }
            } catch (error) {
              console.error("Error fetching audio:", error);
            }
          };

          fetchAndDownloadAudio();
          });
        }
      });
    }
  };
console.log(musicData);

  return (
    <div>
      <>
        {relate.isPending ? (
          <LargeSpinner />
        ) : (
          <div>
            {relate.isSuccess ? (
              <div className="py-6 px-3 sm:px-16 lg:px-20 bg-red-50">
                <h3 className="capitalize text-center text-gray-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold">
                  Related Music
                </h3>
                {relate.data.length ? (
                  <div className="w-full grid gap-y-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-3 lg:grid-cols-4 lg:gap-x-5 px-[1.5rem] sm:px-6 py-8">
                    <div className="relative bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700">
                      <div>
                        <img
                          src={adversiting}
                          alt="pro"
                          className="rounded-t-lg h-[280px] w-full object-cover brightness-75"
                        />
                        <h4> Music uploading price</h4>
                      </div>
                    </div>
                    {relate.data.map((music) => (
                      <MusicCard
                        key={music.id}
                        id={music.id}
                        details_link={"../" + music.title_link}
                        title={music.track_title}
                        downloads={music.downloads}
                        artist_name={music.artist_name}
                        views={music.views}
                        audios={music.audios}
                        image={music.images?.map(
                          (image) => image.artwork_location
                        )}
                      />
                    ))}
                  </div>
                ) : (
                  <Nodata />
                )}
              </div>
            ) : (
              <div> There is error to load data</div>
            )}
          </div>
        )}
      </>
      {query.isPending ? (
        <LargeSpinner />
      ) : musicData ? (
        <div className="fixed bottom-0 left-0 z-50 w-full h-94  px-8 bg-white border-t border-gray-200 md:grid-cols-3 dark:bg-gray-700 dark:border-gray-600">
          {musicData.images && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "1rem auto",
              }}
            >
              {
                <MetaChanger
                  title={musicData?.track_title + " |  Mccullagh Music"}
                  description={
                    musicData?.track_title +
                    " by " +
                    musicData?.artist_name +
                    " - " +
                    musicData?.track_details
                  }
                  OG_description={
                    musicData?.track_title +
                    " by " +
                    musicData?.artist_name +
                    " - " +
                    musicData?.track_details
                  }
                  OG_image={musicData.images[0].artwork_location}
                  OG_audio={
                    musicData.audios &&
                    "https://api.mccullaghmusic.com/" +
                      musicData.audios[currentTrackIndex].track_location
                  }
                  OG_title={musicData?.track_title + " |  Mccullagh Music"}
                />
              }

              <img
                style={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "300px",
                }}
                src={
                  "https://api.mccullaghmusic.com/" +
                  musicData.images[0].artwork_location
                }
                alt="Track Artwork"
              />
              <div
                style={{
                  display: "flex",
                  alignContent: "left",
                  maxWidth: "700px",
                  flexDirection: "column",
                  margin: "1px auto",
                  justifyItems: "left",
                }}
              >
                <h4>
                  <strong>{musicData.track_title}</strong>
                </h4>
                <h5 className="text-gray-700">{musicData.artist_name} </h5>
                <p>{musicData.track_details}</p>
              </div>
            </div>
          )}
          {musicData.audios && (
            <audio
              id="audio-element"
              src={
                "https://api.mccullaghmusic.com/" +
                musicData.audios[currentTrackIndex].track_location
              }
              preload="auto"
            />
          )}

          <div className="fixed bottom-0 left-0 z-50  h-24 w-full  px-8 bg-white border-t border-gray-200 items-center justify-center flex">
            <div className="items-center justify-center flex">
              <button
                onClick={handleDownloadClick}
                type="button"
                className="text-white  flex bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5  dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
              >
                Download
              </button>
            </div>
            <div className="flex items-center w-full">
              <div className="w-full">
                <div className="flex items-center justify-center mx-auto mb-1">
                  <button
                    onClick={playPreviousTrack}
                    data-tooltip-target="tooltip-previous"
                    type="button"
                    className="p-2 group rounded-full hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-600 dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 12 16"
                    >
                      <path d="M10.819.4a1.974 1.974 0 0 0-2.147.33l-6.5 5.773A2.014 2.014 0 0 0 2 6.7V1a1 1 0 0 0-2 0v14a1 1 0 1 0 2 0V9.3c.055.068.114.133.177.194l6.5 5.773a1.982 1.982 0 0 0 2.147.33A1.977 1.977 0 0 0 12 13.773V2.227A1.977 1.977 0 0 0 10.819.4Z" />
                    </svg>
                    <span className="sr-only">Previous video</span>
                  </button>
                  <div
                    id="tooltip-previous"
                    role="tooltip"
                    className="absolute z-10 invisible inline-block px-3 py-0 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                  >
                    Previous video
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                  <button
                    onClick={playPauseToggle}
                    data-tooltip-target="tooltip-pause"
                    type="button"
                    className="inline-flex items-center justify-center p-2.5 mx-2 font-medium bg-red-600 rounded-full hover:bg-red-700 group focus:ring-4 focus:ring-red-300 focus:outline-none dark:focus:ring-red-800 text-center"
                  >
                    {isPlaying ? (
                      "Pause"
                    ) : (
                      <svg
                        className="w-3 h-3 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 10 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M0 .8C0 .358.32 0 .714 0h1.429c.394 0 .714.358.714.8v14.4c0 .442-.32.8-.714.8H.714a.678.678 0 0 1-.505-.234A.851.851 0 0 1 0 15.2V.8Zm7.143 0c0-.442.32-.8.714-.8h1.429c.19 0 .37.084.505.234.134.15.209.354.209.566v14.4c0 .442-.32.8-.714.8H7.857c-.394 0-.714-.358-.714-.8V.8Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}

                    <span className="sr-only">Pause video</span>
                  </button>
                  <div
                    id="tooltip-pause"
                    role="tooltip"
                    className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                  >
                    Pause video
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                  <button
                    onClick={playNextTrack}
                    data-tooltip-target="tooltip-next"
                    type="button"
                    className="p-2.5 group rounded-full hover:bg-gray-100 mr-1 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-600 dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 12 16"
                    >
                      <path d="M11 0a1 1 0 0 0-1 1v5.7a2.028 2.028 0 0 0-.177-.194L3.33.732A2 2 0 0 0 0 2.227v11.546A1.977 1.977 0 0 0 1.181 15.6a1.982 1.982 0 0 0 2.147-.33l6.5-5.773A1.88 1.88 0 0 0 10 9.3V15a1 1 0 1 0 2 0V1a1 1 0 0 0-1-1Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="items-center justify-center flex">
              <button
                data-tooltip-target="tooltip-captions"
                style={{ margin: "auto 2px" }}
                type="button"
                className="p-1 text-gray-500 flex group rounded-full hover:bg-gray-100 mr-1 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-600 dark:hover:bg-gray-600"
              >
                <MdDownload />
                <span style={{ marginTop: "-4px", padding: "0px 0px" }}>
                  {musicData.downloads}
                </span>
              </button>

              <button
                data-tooltip-target="tooltip-expand"
                type="button"
                className="p-1 text-gray-500 flex justfiy-center group rounded-full hover:bg-gray-100 mr-1 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-600 dark:hover:bg-gray-600"
              >
                <MdRemoveRedEye />
                <span style={{ marginTop: "-4px", padding: "0px 0px" }}>
                  {musicData.views}
                </span>
              </button>
            </div>
            <ShareAndCopyButtons
              trackName={musicData.track_title}
              link={"https://play.mccullaghmusic.com/" + musicData.title_link}
            />
          </div>
        </div>
      ) : (
        <Nodata />
      )}
    </div>
  );
};
