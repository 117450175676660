import { createBrowserRouter } from "react-router-dom";
import RouteNotFound from "./pages/RouteNotFound";
import {
  MainRoot,
  AdminRoot,
  AdminHome,
  LandingPage,
  Adminlogin,
  AdminSetPassword,
  AdminForgotPassword,
  Contact,
  AboutRoot,
  ClientRoot,
  AllMusic,
  UpdateMusic,
} from "./pages";
import { Trending } from "./pages/client/Trending";
import { Instruments } from "./pages/client/Instruments";
import { MusicSection, MusicType } from "./components";
import { MusicRoot } from "./pages/client/MusicRoot";
import { Play } from "./pages/client/Play";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainRoot />,
    errorElement: <RouteNotFound />,
    children: [
      {
        path: "/",
        element: <ClientRoot />,
        children: [
          {
            index: true,
            element: <LandingPage />,
          },
          {
            path: "about",
            element: <AboutRoot />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "trending",
            element: <Trending />,
          },

          {
            path: "instruments",
            element: <Instruments />,
          },
          {
            path: "instruments",
            element: <Instruments />,
          },
          {
            path: "music",
            element: <MusicRoot />,
            children: [
              {
                index: true,
                element: <MusicSection />,
              },
              {
                path: ":musicType",
                element: <MusicType />,
              },
            ],
          },
          {
            path: "play",
            element: <MusicRoot />,
            children: [
              {
                index: true,
                element: <MusicSection />,
              },

              {
                path: ":song",
                element: <Play />,
              },
            ],
          },
        ],
      },
      {
        path: "/admin",
        element: <AdminRoot />,
        children: [
          {
            index: true,
            element: <AdminHome />,
          },
          {
            path: "about-us",
            element: <AboutRoot />,
          },
          {
            path: "music",
            element: <AllMusic />,
          },
          {
            path: "update",
            element: <UpdateMusic />,
          },
          {
            path: "instruments",
            element: <Instruments />,
          },
          {
            path: "trending",
            element: <Trending />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
        ],
      },
      {
        path: "/auth/login",
        element: <Adminlogin />,
      },
      {
        path: "/auth/set-password",
        element: <AdminSetPassword />,
      },
      {
        path: "/auth/forgot-password",
        element: <AdminForgotPassword />,
      },
    ],
  },
]);

export default router;
