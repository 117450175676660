import { MdShare } from "react-icons/md";

const ShareAndCopyButtons = ({ trackName, link}) => {
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: trackName,
          url: link,
        });
      } else {
        console.log("Sharing not supported on this browser");
        // You may want to provide a fallback or UI message for browsers that don't support sharing.
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

//   const handleCopy = () => {
//     const textField = document.createElement("textarea");
//     textField.innerText = link;
//     document.body.appendChild(textField);
//     textField.select();
//     document.execCommand("copy");
//     document.body.removeChild(textField);

//     console.log("URL copied to clipboard");
//     // You may want to provide a UI message indicating that the URL has been copied.
//   };

  return (
    <div className="text-white  flex bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-2.5  dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 " onClick={handleShare} style={{ width: "70px", fontSize: "20px", alignItems: "center", justifyContent: "center"}}>
      <MdShare/>
      {/* <button>Share</button> */}
      {/* <button className="copy" onClick={handleCopy}>
        Copy Link
      </button> */}
    </div>
  );
};

export default ShareAndCopyButtons;
